<template>
  <div class="home">

    <div class="front-div" :class="$mq">
      <img src="@/assets/images/damms_bg.jpg" class="damms-bg" />

      <div class="xcontainer" :class="$mq" style="padding-bottom: 2em; padding-top: 2em;">
        <div>
          <p class="text dark-text" :class="$mq">
            Nedrivning af enhver slags. Med mere end 20 års erfaring i branchen kan vi tilbyde enhver type nedrivning.
            Det kan være alt fra fabriksbygninger, anlæg, landbrugsbygninger og højhuse. Enten som total eller delvis nedrivning.
            Vi sørger naturligvis for at alt arbejde udføres på forsvarligt og lovligt vis, samt at materiale bortskaffes på klimavenligt vis.
            Vælger du Damms Nedrivning til at varetage din nedrivning, får du altid et professionelt firma.
          </p>
          <div class="xcontainer text" :class="$mq">
            <ul style="padding-top: 1em; font-weight: 400; text-align: left;">
              <li>Topmoderne udstyr</li>
              <li>20 års erfaring</li>
              <li>Kompetente medarbejdere</li>
            </ul>
          </div>
          <p class="text dark-text" :class="$mq">
            Som medlem af Dansk Industri kan du altid regne med at vi er opdaterede på gældende
            love og regler på området
          </p>
        </div>
      </div>
    </div>

    <div class="divider divider-peaks-up" :class="$mq" style="background-color: var(--primary-color);"></div>

    <div class="xcontainer services-div" :class="$mq">
      <h2 class="light-text" style="padding-bottom: 1em; font-size: 2.5rem;">Ydelser</h2>
      <br>
      <div class="xcontainer" style="max-width: 50em;">
        <Card style="white-space: pre-line;" class="service-card light-service-card" img="nedrivning.jpg" title="Nedrivning" text="Nedrivning af enhver slags. Med mere end 20 års erfaring i branchen kan vi tilbyde enhver type nedrivning.
          Det kan være alt fra fabriksbygninger, anlæg, landbrugsbygninger og højhuse. Enten som total eller delvis nedrivning.
          Vi sørger naturligvis for at alt arbejde udføres på forsvarligt og lovligt vis, samt at materiale bortskaffes på klimavenligt vis.
          Vælger du Damms Nedrivning til at varetage din nedrivning, får du altid et professionelt firma."></Card>

        <Card style="white-space: pre-line;" class="service-card" light img="betonknusning.jpg" title="Betonknusning" text="Vi knuser beton, tegl, sten og asfalt, som kan genbruges for at spare de primære råstoffer. Her kan vi tilbyde knusning med et af markedets nyeste og mest støjsvage anlæg, en Terex Finlay, med en kapacitet på op til 3000 tons om dagen. Samtidig med knusning kan vi tilbyde det nedknuste materiale opsigtet i alle grænse fraktioner og ydermere kan vi tilbyde opstakkerbånd, hvortil der kan lægges 3000 tons under båndet. Dette gør at materialet er nemmere at læsse, da det ikke er fastkørt.
        
        Damms har: Kæbeknuser | Betonhammer | Betonsakse"></Card>

        <Card style="white-space: pre-line;" class="service-card light-service-card" img="modtagerplads.jpg" title="Modtagerplads" text="Vi tilbyder på vores godkendte modtagerplads: I kan indlevere betonbrokker, teglbrokker, sten, asfalt, jern  og lignende. Også med jernarmering og betonspalter. Der modtages ikke tagplader og deponeringsmaterialer. Der modtages ikke gulvklinker og vægfliser, da de kan indeholde forhøjet blymængde (gulvklinker/vægfliser er til deponi affald). Beton med over 1 cm flamingo på modtages ikke. Alle materialer skal så vidt muligt være sorteret for plast, træ, pvc, isolering m.m.
          
          Åbningstider Mandag til Fredag i tidsrummet kl. 07:00 - 17:00. Weekend efter telefonisk aftale."></Card>

        <Card style="white-space: pre-line;" class="service-card" light img="salg_af_materialer.jpg" title="Salg af materialer fra modtagerplads" text="Vi tilbyder
          Knust beton 0/32
          Knust tegl 0/60
          Knust asfalt 0/16 og 0/60
          Vi tilbyder også levering i form af forvogn, kærre, eller med badekar.
          Ønsker du en anden sortering kontakt os om pris."></Card>

        <Card style="white-space: pre-line;" class="service-card light-service-card" img="vejrenovering.jpg" title="Vejrenovering" text="Har du vej eller markvej, der trænger til renovering i alle bredder tilbyder vi denne service med special udlægger maskine og tromling herefter.
          Dette sikrer besparelse af materialer.
          Vi tilbyder også etablering af ny vej/markvej i alle størrelser."></Card>

        <Card style="white-space: pre-line;" class="service-card" light img="udgravning_af_soe.jpg" title="Udgravning/Sø" text="Alle former for udgravning. Også til søer. Alle former for jordarbejde."></Card>

        <Card style="white-space: pre-line;" class="service-card light-service-card" img="ridebane.jpg" title="Ridebane" text="Her tilbyder vi udgravning og opbygning af ridebane med bund efter eget ønske,
          samt opsætning af hegn."></Card>

        <Card style="white-space: pre-line;" class="service-card" light img="maskinpark.jpg" title="Maskinpark" text="Betonknuser, gravemaskiner, lastbil, lastbil med badekar, opstakkerbånd, sorterer, tromle, traktor"></Card>
      </div>
    </div>

    <div class="divider divider-peaks-down" :class="$mq" style="background-color: var(--light-primary-color);"></div>


    <div class="xcontainer contact-div" :class="$mq" style="padding-top: 3em;">
      <div class="text" style="max-width: 30em;">
        <h2 style="color: var(--primary-color)">Kontakt Os</h2>
        <p class="text dark-text" style="padding-bottom: 1em;">Kontakt Os Omkring Vores Ydelser</p>
        <p class="text dark-text contact-text">
          Telefon: <b>23 27 58 81</b><br>
          Mail: <b>sosdamms@gmail.com</b>
        </p>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2272.58640019376!2d9.351805030846183!3d55.27790936963397!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464ca839d442559b%3A0x954adfa208e05764!2sStydingvej%202%2C%206500%20Vojens%2C%20Denmark!5e0!3m2!1sen!2sse!4v1630178094500!5m2!1sen!2sse" width="600" height="450" style="border:0; max-width: 100%; padding-top: 1em;" allowfullscreen="" loading="lazy"></iframe>
      </div>
    </div>
  
  </div>
</template>

<script>
  import Card from '@/components/Card.vue'

  export default {
    name: 'Home',
    components: {
      Card
    },
    data() {
      return {
        contactForm: {
          name: '',
          company: '',
          tlfnr: '',
          email: '',
          message: ''
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .front-div {
    padding-top: 4.5em;
    background: var(--primary-color);
    text-align: center;
    height: 80vh;
    min-height: 58em;
  }

  .front-div.mobile {
    min-height: 48em;
  }

  .text {
    width: 75%;
    max-width: 50em;
    margin: auto;
    text-align: center;
    font-weight: 300;
  }

  .text.desktop {
    font-size: 1.1rem;
  }

  .intro-text {
    font-weight: 400;
  }

  .intro-text.mobile {
    padding-bottom: 4em;
  }

  .intro-text.desktop {
    padding-bottom: 5em;
    max-width: 40em;
    font-size: 1.3rem;
  }

  .damms-bg {
    width: 100%;
    max-width: 60em;
  }

  .damms_logo {
    margin: auto;
  }

  .damms_logo.desktop {
    
    padding-top: 6em;
    padding-bottom: 3em;
    width: 15em;
  }

  .damms_logo.mobile {
    padding-top: 4em;
    padding-bottom: 2.5em;
    width: 10em;
  }

  .btn-commerce, .btn-demolition {
    font-size: 1.2rem;
  }

  .btn-secondary:focus, .btn-secondary:not(:disabled):not(.disabled).active {
    background: var(--light-accent-color) !important;
    border-color: var(--light-accent-color) !important;
    color: var(--primary-text);
  }

  .btn-site:hover {
    background: var(--light-accent-color);
    border-color: var(--light-accent-color);
    color: var(--primary-text);
  }

  .input {
    margin-bottom: .5em;
  }

  .contact-div {
    background: var(--light-primary-color);
    max-height: 100vh;
    min-height: 40em;
    padding-bottom: 4em;
    text-align: center;
  }

  .contact-div.desktop {
    height: 50em;
  }

  .contact-text {
    font-weight: 400;
    font-size: 1rem;
  }

  .services-div {
    padding-bottom: 8em;
    padding-top: 6em;
    background: var(--dark-primary-color);
  }

  .services-div.desktop {
    padding-top: 5em;
    padding-bottom: 8em;
  }

  .form-control, .form-control:focus {
    border-radius: 0;
    border-bottom: 1px solid var(--light-primary-color);
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background: transparent;
  }

  .service-card {
    padding: 1em;
    padding-top: 1.8em;
    background: var(--dark-primary-color);
  }

  .light-service-card {
    background: var(--light-primary-color);
  }
</style>