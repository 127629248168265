<template>
  <footer>
      <div class="xcontainer footer-div">
        <p style="font-size: 0.8rem; color: #8A8A8A;">&copy; {{year}} Damms Nedrivning ApS</p>

        <div>
            <h6 class="light-text">Kontakt Damms Nedrivning ApS</h6>
            <p class="light-text" style="font-size: 0.9em;">
                Telefon: <b>23 27 58 81</b><br/>
                Mail: <b>sosdamms@gmail.com</b><br/>
                Leverandør mail: <b>Fakturadamms@gmail.com </b><br/>
                Stydingvej 2<br/>
                6500 Vojens<br/>
                CVR NR: <b>38262386</b>
            </p>
        </div>
        
        <p style="font-size: 0.8em; color: var(--light-accent-color);"><b>Find os her</b></p>
        <div style="display: flex;">
            <a href="https://www.facebook.com/Dammsknusning/">
                <b-img src="@/assets/icons/facebook.svg" class="social-media-icon" />
            </a>
        </div>
      </div>
  </footer>
</template>

<script>
export default {
    computed: {
        year() { return new Date().getFullYear(); }
    }
}
</script>

<style lang="scss" scoped>
    .footer-div {
        background: var(--dark-primary-color);
        height: 20em;
    }

    .social-media-icon {
        width: 2em;
        padding: 0 0.3em 0 0.3em;
        cursor: pointer;
    }
</style>
