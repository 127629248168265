<template>
    <div class="card-div" :class="$mq">
        <div :style="`min-width: 8em; max-width: 8em; height: 8em; background: var(--${light ? 'light' : 'dark'}-accent-color);`">
            <b-img :src="require(`@/assets/images/${img}`)" style="width: 100%; height: 100%; object-fit: cover; transform: translate(.6em,-.6em);"></b-img>
        </div>
        <div class="text-div" :class="$mq">
            <h4 class="dark-text" :style="`font-weight: 600; color: var(--${light ? 'light-primary' : 'primary-text'}-color);`">{{title}}</h4>
            <p class="text-div-body" :class="$mq" :style="`color: var(--${light ? 'light-primary' : 'primary-text'}-color);`">{{text}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Card',
    props: {
        title: String,
        text: String,
        img: String,
        light: Boolean
    },
}
</script>

<style lang="scss" scoped>
    .card-div {
        display: flex;
        width: 100%;
    }

    .card-div.mobile {
        display: grid;
        width: 100vw;
    }

    .text-div.desktop {
        margin-left: 1.5em;
    }

    .text-div.mobile {
        margin-top: 1em;
    }

    .text-div-body.desktop {
        font-size: .9rem;
        font-weight: 400;
        margin-left: 0.3em;
    }

    .text-div-body.mobile {
        font-size: .9rem;
        font-weight: 400;
        margin-left: 0.3em;
    }
</style>